<template>
  <div>
    <ButtonsCta
      class="variant-secondary !px-4 !py-[6px] !border-2 !border-white/50 md:!border-0 !box-border md:!py-2"
      @click="getStartedStore.modalState = true"
    >
      <template v-if="!isXs">
        {{ $t("Get started") }}
      </template>
      <Icon
        v-else
        name="IconAddUser"
        size="20"
        class="rtl:-scale-x-100"
      ></Icon>
    </ButtonsCta>
    <!--@on-overlay-click="onModalClose"-->
    <Dialog
      :modal="getStartedStore.modalState"
      :modal-class="`${modalBg} z-[999]`"
    >
      <template #close>
        <div
          :class="[
            getStartedStore.step == 'landing'
              ? 'justify-end'
              : 'justify-between',
          ]"
          class="px-10 py-5 flex"
        >
          <ButtonsIcon
            v-if="
              !['landing', 'createLinkSent', 'createCodeSent'].includes(
                getStartedStore.step
              )
            "
            is-dark
            icon-name="IconArrowLeft"
            class="touch-auto pointer-events-auto rtl:rotate-180"
            @click="onBack"
          ></ButtonsIcon>

          <ButtonsIcon
            class="ms-auto"
            is-dark
            icon-name="IconTimesWhite"
            @click="onModalClose"
          ></ButtonsIcon>
        </div>
      </template>
      <Auth @on-guest="onModalClose" />
    </Dialog>
  </div>
</template>

<script setup>
import { useGetStartedStore } from "@/stores/getStarted";
import { useSessionStore } from "@/stores/session";
const { isXs } = useBreakpoints();
const sessionStore = useSessionStore();
const getStartedStore = useGetStartedStore();
const router = useRouter();
const route = useRoute();

validateRoute();

const modalBg = computed(() => {
  if (getStartedStore.step == "landing") {
    return "shots";
  }

  return "gradient";
});

function validateRoute() {
  const routeIncludesModal = Object.keys(route.query).includes("modal");
  const modalIsGetStarted = route.query.modal == "get-started";
  const hasSessionToken = sessionStore.token != null;

  if (routeIncludesModal && modalIsGetStarted && !hasSessionToken) {
    getStartedStore.modalState = true;
    router.replace({});
  }
}

const onModalClose = () => {
  getStartedStore.closeModal();
};

const onBack = () => {
  const { step } = getStartedStore;
  if (["signin", "createAccount"].includes(step)) {
    getStartedStore.step = "landing";
  }

  if (step == "reset") {
    getStartedStore.step = "signin";
  }
  if (step == "resetPasswordValidateCode") {
    getStartedStore.step = "reset";
  }

  if (step == "createPassword") {
    askToConfirm();
    getStartedStore.step = "createAccount";
  }

  if (step == "createDetails") {
    askToConfirm();
    getStartedStore.step = "createAccount";
  }
};

const askToConfirm = () => {};
</script>

<style lang="scss" scoped></style>
